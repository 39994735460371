<template>
  <v-form>
    <v-select v-model="dataType" :items="dataTypes" label="Data type" />
    <v-flex xs12 class="pa-0">
      <v-btn-toggle v-model="by" mandatory depressed>
        <v-btn value="month" outline depressed color="primary">
          Month
        </v-btn>
        <v-btn value="year" outline depressed color="primary">
          Year
        </v-btn>
      </v-btn-toggle>
    </v-flex>
    <between-date-input v-model="betweenDates" :type="by" />
    <v-select-locale v-model="locales" multiple />
    <v-autocomplete-website v-model="websites" multiple />
    <v-autocomplete-tag
      label="Filtered tags"
      v-model="filterTags"
      multiple
      :type="'ARTICLE'"
    />
    <v-autocomplete-tag
      label="Excluded tags"
      v-model="excludedTags"
      multiple
      :type="'ARTICLE'"
    />
  </v-form>
</template>
<script type="text/babel">
import VSelectLocale from "../../locale/VSelectLocale";
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteWebsite from "@/components/website/VAutocompleteWebsite";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";
import VAutocompleteTag from "@/components/tag/VAutocompleteTag";

import { intersection } from "lodash";

const { mapComputedFilters } = createNamespacedHelpers(
  "articles-stats-timetable"
);

export default {
  name: "articles-timetable-filters",
  components: {
    VAutocompleteTag,
    BetweenDateInput,
    VAutocompleteWebsite,
    VSelectLocale
  },
  props: {
    store: {
      type: String,
      required: true
    },
    dataTypes: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapComputedFilters([
      "locales",
      "websites",
      "by",
      "from",
      "to",
      "search",
      "filterTags",
      "excludedTags",
      "dataType"
    ]),
    betweenDates: {
      get() {
        return { from: this.from, to: this.to };
      },
      set(dates) {
        this.from = dates.from;
        this.to = dates.to;
      }
    }
  },
  watch: {
    search(newVal, oldVal) {
      if (newVal.length > oldVal.length) {
        this.websites = null;
      }
    },
    filterTags(filterTags) {
      const overlap = intersection(filterTags, this.excludedTags);
      if (overlap.length) {
        this.excludedTags = this.excludedTags.filter(
          tag => !overlap.includes(tag)
        );
      }
    },
    excludedTags(excludedTags) {
      const overlap = intersection(excludedTags, this.filterTags);
      if (overlap.length) {
        this.filterTags = this.filterTags.filter(tag => !overlap.includes(tag));
      }
    }
  }
};
</script>
