<template>
  <tr>
    <td>
      <a :href="article.url" target="_blank">{{
        article.url
          ? article.url.replace("http://www.", "").replace("https://www.", "")
          : ""
      }}</a>
    </td>
    <td>{{ article.total }}</td>
    <td v-for="(count, key) in sortedDataCounts" :key="key">
      {{ count.data }}
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapArticleGetters } = createNamespacedHelpers(
  "articles-stats-timetable"
);

export default {
  name: "articles-timetable-table-row",
  props: {
    articleId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapArticleGetters([FIND_BY_ID, "dataCounts"]),
    article() {
      return this[FIND_BY_ID](this.articleId);
    },
    sortedDataCounts() {
      return this.dataCounts(this.article.id);
    }
  }
};
</script>
